/* Components/NotFound/NotFound.css */
.not-found {
    text-align: center;
    padding: 50px;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .not-found p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .home-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
  }
  