.Footer_container{
    border-top: 4px double rgba(0, 0, 0, 0.4);
    margin-top: 5rem;
    padding: 2rem 10%;
    width: 100%;
}
.Footer_logo{
    width: 12rem;
    filter: drop-shadow(3px 3px 15px black);
    padding: 0.5rem;
    margin-left:-1rem;
}
#Footer_logo_side_Name{
    font-size: 20px;
    font-weight: bolder;
    color: black;
}
.Footer_logo_bottom_About{
    color: black;
}
.Footer_Get_In_Touch span{
    float: left;
    font-size: 20px;
    height: 2.2rem;
    color: black;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Footer_Soical_Icon_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
#Footer_Soical_Icon_container li{
    margin: -1rem 2rem;
}

#Footer_Soical_Icon_container li a{
    font-size: 24px;
    color: black;
}

.footer_icon_get_touch{
    margin-top: -0.5rem;
}

.footer_text{
    color: black;
    margin-top: 0.5rem;
    text-decoration: none;
    margin-left: 0.5rem;
}

.footer_Copyright{
    color: black;
    text-align: center;
}

@media screen and (max-width: 580px){
    .Footer_container{
        margin-top: 2rem;
        padding: 2rem 2%;
    }

    .Footer_logo{
        margin: 1.5rem 0;
    }

    #Footer_logo_side_Name{
        margin:0 2rem;
    }
    .Footer_logo_bottom_About{
        padding:0 2rem;
    }

    #Footer_Soical_Icon_container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    #Footer_Soical_Icon_container li{
        margin: -1rem 0;
    }

    
}

@media screen and (max-width: 400px){
    .footer_Copyright{
        height: 20vh;
    }
}