#Time_container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.Time_Form_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.Time_page_form_container {
    background: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 300px;
    max-height: 500px;
    position: relative;
    z-index: 999;
}

#Time_page_form_heading{
    font-size: 24px;
    font-weight: bolder;
    color: black;
    font-family: sans-serif;
}

.Time_page_form_input_box {
    border: 1px solid #666;
    padding: 0.2rem 0.5rem;
    margin: 15px 0;
    width: 100%;
}

.Time_page_form_input{
    border: none;
    outline: none;
    width: 100%;
    padding: 0.1rem;
}

.Time_Form_btn {
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bolder;
    margin-bottom: 10px;
    margin-right: 1rem;
}

.Time_Form_btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.Time_Form_container p {
    text-align: center;
    color: #666;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
}
