
/* home about */
.Home_page_Heading{
    text-align: center;
    margin: 2rem 10%;
}

#Home_page_Paragraph{
    margin: 0 10%;
    text-align: justify;
}

.Home_Heading{
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    margin: 3rem 0;
    font-family: Baskerville Old Face;
}


/* Location Image style start */

.location_prea{
    margin:  -1.5rem 10% 0 10%;
}

.Location_Images_Container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem 0;
    padding: 0.5rem 0;
    gap: 2%;
}

.Img-Name-Container{
    margin-bottom: 3.5rem;
    text-align: center;
}
.Location_Image{
    border-radius: 30px;
    width: 100%;
    height: 100%;
}

.Location_img_name{
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    font-family: Bernard MT Condensed;
}


.App {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}



/* Start Home Page Second Income style */
#Second_Income_Img_Container{
    text-align: center;
}
#Second_Income_header{
    font-family:Bernard MT Condensed;
    color: white;
}

#Second_Income_perag{
    color: white;
    font-size: 18px;
}

.Second_Income_head{
    color: white;
    font-size: 20px;
}

.Second_Income_perag{
    color: white;
    font-size: 18px;
}
.Second_Income_Home_Page_Container{
    background-color:#c29d51;
    padding:2rem 10%;
}


.Second_Income_Img{
    width: 60%;
    height: 60%;
    margin: 2rem 0;
}

/* Start Home Page Project style */
.line-center{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.line-only{
    width: 42%;
}

#Home_Page_Project_Paragraph{
    text-align: center;
}

.Home_Page_Project_Container{
    border: 1px solid black;
    width: 280px;
}

.Project_Container{
    margin: 0 10%;
    padding: 1.5rem;
}

.Home_Page_Project_img_Container{
    width: 100%;
    margin-bottom: 1rem;
}

.Home_Page_Project_img{
    width: 100%;
    height: 100%;
}

.Home_Page_Project_link{
    border: none;
    margin: 1.5rem 0;
    padding: 0.5rem 1rem;
}

.CasaMelo_navlink{
    text-decoration: none;
    color: black;
    font-weight: bold;
}

/* Explore the Beauty of Mountain Homes */

.home_img2_container{
    padding: 2rem 10%;
    margin: 2rem 0;
    background-color: rgb(86, 120, 101);
}

#home-image2{
    width: 50%;
    float: left;
    padding: 0.1rem 1rem;
    border-radius: 30px;
}

.text-color{
    color: white;
}

.read-more-btn{
    border: none;
    cursor: pointer;
    font-weight: bold;
    background-color: rgb(86, 120, 101);
    color: #c29d51;
}

/* Blog start */

.Home_Blog{
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    margin: 1rem 0;
    font-family: Baskerville Old Face;
}

#B-Contaoner{
    margin-top: -3rem;
}
.Blog_Container{
    border: 1px solid black;
    height: 80vh;
}

.Blog_img{
    width: 100%;
    height: 250px;
}

.Blog_Container_Infor{
    padding: 1rem;
}
.Blog_btns{
    padding: 0.5rem 1rem;
    margin: 1rem ;
    border: none;
    font-weight: bolder;
    background-color: #c29d51;
}
.Blog_link{
    text-decoration: none;
    color: white;
}

.Home_Blog_btns{
    display: none;
    padding: 0.5rem 1rem;
    margin: 1rem ;
    border: none;
    font-weight: bolder;
    background-color: #c29d51;
}


#Youtube-contaner{
    margin: 1rem 10% 0 10%;
}

#youtube-video{
    width: 450px;
    height: 300px;
    margin: 0.1rem 0;
    border-radius: 20px;
    border: 5px solid rgb(86, 120, 101);
}
@media screen and (max-width: 1050px){
    #home-image2{
        height: 300px;
    }
}

@media screen and (max-width: 992px){
    .line-only{
        width: 38%;
    }

    #Second_Income_perag{
        color: white;
        font-size: 16px;
    }
}

@media screen and (max-width: 900px){
    #home-image2{
        width: 320px;
        height: 320px;
    }
}

@media screen and (max-width: 770px){
    .Second_Income_Img{
        width: 100%;
        height: auto;
        margin: 0.5rem 0;
    }
    #Second_Income_perag{
        color: white;
        font-size: 18px;
        margin-top: 1rem;
    }
    #home-image2{
        width: 100%;
        height:100%;
        border-radius: 30px;
    }
    .line-only{
        width: 35%;
    }

    #youtube-video{
        width: 280px;
        height: auto;
    }
}

@media screen and (max-width: 580px){
    #Home_page_Paragraph{
        margin: 0 5%;
    }
    .line-only{
        width: 30%;
    }

    .Location_Image{
        border-radius: 30px;
        width:250px;
        height:250px;
    }

    .Project_Container{
        margin: 0 5%;
    }
}

@media screen and (max-width: 400px){
    
    .line-only{
        width: 25%;
    }
}

@media screen and (max-width: 300px){
    .Home_Page_Project_Container{
        width: 250px;
    }
}