@keyframes Fixappoitment {
    0% {
        background-color: #c29d51;
        color: white;
        border: 1px solid #c29d51;
    }
    25% {
        background-color: white;
        color: #c29d51;
        border: 1px solid #c29d51;
    }
    50% {
        background-color: #c29d51;
        color: white;
        border: 1px solid #c29d51;
    }
    75% {
        background-color: white;
        color: #c29d51;
        border: 1px solid #c29d51;
    }
    100% {
        background-color: #c29d51;
        color: white;
        border: 1px solid #c29d51;
    }
}

.fix {
    position: fixed;
    top: 55%;
    left: 1rem;
    transform: rotate(-90deg);
    transform-origin: left center;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bolder;
    animation: Fixappoitment 2s infinite ease-in-out;
    z-index: 777;
    background-color: #c29d51;
    color: white;
    border: 1px solid #c29d51;
}

/* Form container styling */
.Fix_appoitnment_container {
    width: 300px;
    height: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999; /* Above the overlay */
}
.Fix_appoitnment_close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.Fix_appoitnment_input_box {
    margin-bottom: 15px;
}

.Fix_appoitnment_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Fix_appoitnment_btn_box {
    text-align: center;
}

.Fix_appoitnment_btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.Fix_appoitnment_btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9998; /* Below the form */
}
