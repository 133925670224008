/* CallNow.css */

#Call_Now_btn {
    border: 1px solid green;
    background-color: green;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
}

.whatsapp_icon {
    border-radius: 50%;
    position: fixed;
    right: 5%;
    bottom: 10%;
    background-color: #25D366;
    color: white;
    width: 3rem;
    height: 3rem;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    z-index: 777;
}

.visible {
    display: flex;
}

@media screen and (max-width: 400px) {
    #Call_Now_btn {
        display: flex; /* Initially hidden for small screens, visible class will override */
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
