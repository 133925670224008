.Footer_Above_container{
    background-image: url('../Images/footerAboveimg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.Footer_From_Description {
    text-align: center;
    padding:2rem 10%;
    font-family:Arial;
    margin-top: 3rem;
}

#Footer_Above_From_container {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 333;
    padding: 2rem 0;
}

.Footer_Above_From_box {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    width: 300px;
    text-align: center;
}

.Footer_Above_From_input {
    border: 1px solid rgb(155, 154, 154);
    background-color: white;
    border-radius: 5px;
    height: 2rem;
    width: 13.7rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer_Above_input {
    height: 1.5rem;
    width: 13rem;
    border: none;
    outline: none;
}

.Footer_Above_From_textarea {
    border: 1px solid rgb(155, 154, 154);
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    width: 13.7rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer_Above_textarea {
    border-radius: 5px;
    width: 13rem;
    height: 4.5rem;
    border: none;
    outline: none;
    resize: none;
}

#Footer_Above_Contcat_Submit_but {
    border: 1px solid aqua;
    background-color: white;
    color: aqua;
    width: 5rem;
    height: 2rem;
    outline: none;
    border-radius: 10px;
    font-weight: bolder;
    cursor: pointer;
}

#Footer_Above_Contcat_Submit_but:disabled {
    border: 1px solid grey;
    color: grey;
    cursor: not-allowed;
}

#Footer_Above_Contcat_Submit_but:hover:enabled {
    background-color: aqua;
    color: white;
}

@media screen and (max-width: 400px){
    #Footer_Above_From_container {
        height: 50vh;
    }
}
